.dark-calendar-card-content{
    padding-bottom: 16px !important;
}
.quick-search-block, .video-tile-block, .calendar-block{
    /* padding-top: 4.9em; */
}
.calendar-block{
    padding: 0 2em !important;
}
.quick-search-card-content{
    padding: 5px 3px !important;
}
.quick-search-card{
    margin-bottom: 12px;
    text-align: center;
    width: 5.3em;
    height: 5.3em;
    border-radius: 8px !important;
    font-size: 12px;
    border: 2px solid #404040;
}
.quick-search-block{
    padding: 2em 4.9em 0 4.9em !important;
}
.quick-search-titles{
    font-size: 10px;
    font-weight: 600;
}
.media{
    height: 25em;
    width: 100%;
    object-fit: cover;
    padding: 10px;
}
.video-duration{
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}
.video-title{
    margin: 0 0 5px 0;
}
.signup-btn{
    background-color: #51c0db !important;
    color: #ffffff !important;
    border-radius: 15% !important;
    font-size: 10px !important;
    border-radius: 30px !important;
}
.dark-calendar-card{
    background-color: #404040 !important;
    border-radius: 10px !important;
}
.calendar-block p{
    margin: 0 !important;
    color: #ffffff;
}
.starting-time p{
    font-size: 12px;
    font-weight: 500;
}
.starting-time{
    text-align: right;
    padding-bottom: 0 !important;
    margin-right: 0.5em !important;
    z-index: 3;
}
.event-detail{
    font-size: 14px;
    padding-top: 0 !important;
    font-weight: 600;
    z-index: 3;
}
.font-weight-bold{
    font-weight: bold;
}
.expand-more-btn{
    text-align: center;
    color: #ffffff;
    cursor: pointer;
    margin-top: 1em;
    z-index: 4;
}
.react-horizontal-scrolling-menu--item {
    min-width: 16em;
    margin-right: 2em;
}
.video-slider-media{
    height: 7em;
    width: 100%;
    object-fit: cover;
    /* padding: 5px; */
}
.video-card{
    border-radius: 10px !important;
}
.video-card-content{
    padding: 10px !important;
}
.arrow-buttons{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 1%;
    user-select: none;
    margin-top: 5.5em;
    height: fit-content;
    width: fit-content;
    border-radius: 100%;
    padding: 2px;
    border: none;
    background-color: #ffffff;
}
.arrow-left{
    position: absolute;
    left: 15em;
    z-index: 1;
}
.arrow-right{
    position: absolute;
    right: 2em;
    z-index: 1;
}
.scroll-menu .video-title{
    font-size: 12px;
}
.scroll-menu .video-title{
    font-size: 12px;
}         
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .scroll-menu-title{
      font-size: 14px;
      margin-left: 21em;
      margin-bottom: 5px;
  }
  .react-horizontal-scrolling-menu--wrapper {
      margin-bottom: 2em;
  }
  .video-card:hover .overlay{
    /* display: block; */
    position: absolute;
    top: 0;
    height: 100%;
    width: 16em;
    border-radius: 10px;
    /* right: 0; */
    background-color: #404040;
    opacity: 0.4;
  }
  .video-card:hover .play-button{
    display: block !important;
    position: absolute;
    top: 0;
    left: 45%;
    z-index: 1;
    padding: 2px 3px 1px 3px;
  }
  .video-scroll-menu-text{
      font-size: 12px;
      margin: 0 !important;
  }
  .expand-btn{
    background-color: transparent !important;
    color: #ffffff !important;
    border-radius: 15% !important;
    font-size: 10px !important;
    border-radius: 30px !important;
  }
  .expand-area{
    justify-content: center;
    background: #404040;
    margin-top: 5px;
    padding: 0;
  }
  .quick-search-icons{
      height: 100%;
  }
  .event-container{
    margin: 1em 0;
    padding: 0 0 1em 0;
  }
  .image-bg{
    width: 100%;
    position: absolute;
    opacity: 0.5;
    height: 100%;
    border-radius: 10px;
  }