.section-title{
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-left: -13em;
    color: #404040;
}
.create-user-form .create-user-button{
    background-color: #51c0db;
    color: #ffffff;
    padding: 8px 20px;
    font-size: 10px;
    border: none;
    border-radius: 20px;
    margin-left: 23rem;
}
.create-user-form .create-user-form-input input{
    background-color: #ffffff !important;
    border-radius: 30px !important;
    text-decoration: none;
    padding: 5px 10px;
    width: 23em;
}
.create-user-form{
    margin-top: 2em;
}
.create-user-form .MuiInput-underline:after, .create-user-form .MuiInput-underline:before, .create-user-form .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: none;
}
.create-user-form label{
    width: 6em;
    font-size: 12px;
    font-weight: bold !important;
    color: #000000;
}
.create-user-form .align-items-center{
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    justify-content: center;
}