.banner {
    position: relative;
    width: 100%;
    height: auto;
    color: #f1f1f1;
    object-fit: contain;
    background-repeat: no-repeat;
}

.bannerContents {
    margin-left: 30px;
    padding-top: 140px;
    height: 190px;
}

.bannerTitle {
    color: #51c0db;
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0.3rem;
}

.bannerDescription {
    width: 45rem;
    line-height: 1.3;
    font-size: 0.8rem;
    max-width: 360px;
    height: 80px;
}

.bannerFadeBottom {
    height: 7.4rem;
    background-image: linear-gradient(
        180deg,
        transparent,
        #e3f0f1,
        #f1f1f1
    );
}

.bannerButton {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    margin-top: 1rem;
    margin-right: 1rem;
    background-color: #51c0db;
    text-transform: uppercase;
    padding: 0.5rem 2rem 0.5rem 2rem;
}

.bannerButton:hover {
    color: #036881;
    background-color: #e6e6e6;
    transition: all 0.2s;
}