.all-videos-page{
    /* margin-top: 4.5em !important; */
}
.all-videos-page .filter-label{
    font-size: 12px !important;
    font-weight: bold !important;
    background-color: #404040;
    width: 100%;
    border-radius: 20px;
    padding: 5px 10px !important;
    color: #ffffff !important;
    margin: 0 0 0.5em 0 !important;
}
.all-videos-page .filter-options{
    font-size: 12px !important;
    font-weight: bold !important;
    background-color: #ffffff;
    width: 100%;
    border-radius: 20px;
    padding: 3px 10px !important;
    color: #404040 !important;
    margin: 0 0 0.5em 0 !important;
    justify-content: space-between;
}
.all-videos-page .MuiFormControlLabel-label{
    font-size: 12px !important;
    color: #404040;
    font-weight: bold !important;
}
.all-videos-page .MuiRadio-root{
    padding: 0 !important;
}
.all-videos-page .form-control{
    padding: 0 2em !important;
    width: 100%;
    margin-bottom: 2em !important;
}
.all-videos-page .form-control .MuiRadio-root span svg:nth-child(2){
    font-size: 2.25rem;
    left: -8px;
    top: -8px;
}

/* Video card css */
.all-videos-page .video-card:hover .overlay{
    /* display: block; */
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    /* right: 0; */
    background-color: #404040;
    opacity: 0.4;
  }
  .all-videos-page .video-card:hover .play-button{
    display: block !important;
    position: absolute;
    top: 0;
    left: 45%;
    z-index: 1;
    padding: 2px 3px 1px 3px;
  }
  .all-videos-page .video-scroll-menu-text{
    font-size: 12px;
    margin: 0 !important;
}
.all-videos-page .font-weight-bold{
    font-weight: bold;
}
.all-videos-page .video-slider-media{
    height: 7em;
    width: 100%;
    object-fit: cover;
    /* padding: 5px; */
}
.all-videos-page .video-card{
    border-radius: 10px !important;
}
.all-videos-page .video-card-content{
    padding: 10px !important;
}
.all-videos-page .arrow-buttons{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 1%;
    user-select: none;
    margin-top: 5.5em;
    height: fit-content;
    width: fit-content;
    border-radius: 100%;
    padding: 2px;
    border: none;
    background-color: #ffffff;
}
.all-videos-page .scroll-to-top-button{
    z-index: 2;
    background-color: #404040 !important;
    color: #ffffff !important;
    position: fixed !important;
    bottom: 1em;
    right: 0;
    padding: 6px 8px 2px 8px !important;
    border-radius: 30px !important;
    min-width: fit-content !important;
}
.all-videos-page .video-block-title{
    margin-top: 0;
    font-size: 12px;
    font-weight: 800 !important;
    color: #404040;
}