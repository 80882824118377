.nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: auto;
    z-index: 1; 
    

    /* Animations */
    transition-timing-function: ease-in;
    transition: all 0.5s;
}

.navbar{
    background-color: #f1f1f1 !important;
    color: #000000 !important;
    box-shadow: none !important;
}

.navWhite {
    background-color: #f1f1f1;
}

.navLogo {
    /* position: fixed; */
    top: 20px;
    left: 0;
    width: 7em;
    object-fit: contain;
    flex-grow: 1;
    cursor: pointer;
}

.navAvatar {
    cursor: pointer;
    position: fixed;
    top: 20px;
    right: 1.6em;
    width: 43px;
    height: auto;
}

.navList {
    margin-left: 3em;
    list-style-type: none;
    height: 100%;
    justify-content: baseline;
    position: relative;
}

.navItems {
    margin-right: 20px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: #404040;
    text-decoration: none;
}

.navItems.active{
    color: #51c0db !important;
}

.navItems a:link {
    color: #51c0db;
    text-decoration: none;
}

.navItems a:visited {
    color: #51c0db;
}

.navItems a:hover {
    color: #51c0db;
    text-decoration: underline;
}

.navItems a:active {
    color: #51c0db;
}

.searchBlock, .searchIcon{
    display: flex;
    align-items: center;
}
.searchBlock{
    background: #ffffff;
    padding: 0 5px;
    border-radius: 15px;
    position: absolute;
    right: 6em;
}
.inputInput{
    font-size: 12px !important;
    padding: 7px 5px !important;
}
.searchIcon .MuiSvgIcon-root{
    height: 0.8em;
    width: 0.8em;
}

.language-icon{
    position: fixed !important;
    right: 0.5em;
}
.align-items-center{
    display: flex;
    align-items: center;
}
.flag-icon{
    width: 15px;
    margin-right: 7px;
}
.selected-language{
    font-size: 1.2em !important;
    margin-left: 5px;
}
.profile-links{
    text-decoration: none;
    color: #404040;
}