.signUpForm {
    height: 100vh;
}

.signUpFormContent {
    position: absolute;
    top: 10%;
    max-width: 300px;
    padding: 70px;
    margin-right: auto;
    margin-left: auto;
    color: #fff;
    background: #404040;
    border-radius: 10px;
    left: 0;
    right: 0;
} 
.signUpFormContent > form {
    display: grid;
    flex-direction: column;
}

.signUpFormContent > form > h1 {
    text-align: left;
    margin-bottom: 25px;
}

.signUpFormContent > form > input {
    outline-width: 0;
    height: 40px;
    margin-bottom: 14px;
    border-radius: 5px;
    border: none;
    padding: 5px 15px;
}

.signUpFormContent > form > button {
    padding: 16px 20px;
    font-size: 1rem;
    color: #f1f1f1;
    border-radius: 5px;
    background-color: #51c0db;
    font-weight: 600;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

.signUpFormOpret{
    text-align: left;
    margin-bottom: 30px;
}

.signUpFormOpret a:link{
    color: #f1f1f1;
}

.signUpFormOpret a:visited{
    color: #f1f1f1;
}

.signUpFormOpret a:hover{
    color: #51c0db;
}

.signUpFormOpret a:active{
    color: #51c0db;
}