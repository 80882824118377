.skema-page{
    margin: 0 2em;
}
/* Video card css */
.skema-page .video-card:hover .overlay{
    /* display: block; */
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    /* right: 0; */
    background-color: #404040;
    opacity: 0.4;
  }
  .skema-page .video-card:hover .play-button{
    display: block !important;
    position: absolute;
    top: 0;
    left: 45%;
    z-index: 1;
    padding: 2px 3px 1px 3px;
  }
.skema-page .card-detail-text{
    font-size: 11px;
    margin: 0 !important;
    display: flex;
    align-items: center;
    font-weight: 500;
}
.skema-page .font-weight-bold{
    font-weight: bold;
}
.skema-page .video-slider-media{
    height: 7em;
    width: 100%;
    object-fit: cover;
    /* padding: 5px; */
}
.skema-page .video-card{
    border-radius: 10px !important;
}
.skema-page .video-card-content{
    padding: 10px !important;
}
.skema-page .arrow-buttons{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 1%;
    user-select: none;
    margin-top: 5.5em;
    height: fit-content;
    width: fit-content;
    border-radius: 100%;
    padding: 2px;
    border: none;
    background-color: #ffffff;
}
.skema-page .scroll-to-top-button{
    z-index: 2;
    background-color: #404040 !important;
    color: #ffffff !important;
    position: fixed !important;
    bottom: 1em;
    right: 0;
    padding: 6px 8px 2px 8px !important;
    border-radius: 30px !important;
    min-width: fit-content !important;
}
.skema-page .video-block-title{
    margin-top: 0;
    font-size: 12px;
    font-weight: 800 !important;
    color: #404040;
}

.skema-page .column-title{
    margin-right: 20px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: #404040;
    text-decoration: none;
    /* margin: 0; */
}
.skema-page .card-title{
    margin-right: 20px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    color: #404040;
    text-decoration: none;
    margin: 0;
}
.skema-page .text-align-center{
    text-align: center;
}
.skema-page .flag-icon{
    width: 20px;
    margin: 0 2px 0 0 !important;
}
.skema-page .material-icon{
    font-size: 1.5em;
    margin-right: 7px;
    margin-left: 3px;
}