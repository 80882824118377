.footer {
    color: #404040;
    background-color: #f1f1f1;
    padding: 25px 25px 50px 25px;
    width: 100%;
    position: relative;
}

/* .footLogo {
    
} */

.footLogoImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    width: 200px;
    cursor: pointer;
    z-index: 1;
}

.footInfo {
    /* border-top: #404040;
    border-style: solid none none none; */
    text-align: center;

}

.footHead {
    color: #51c0db;
    padding-top: 20px;
    padding-bottom: 5px;
}

/* .footLink {
    font-weight: 600;

} */

.footInfo a:link{
    color: #404040;
    text-decoration: none;
}

.footInfo a:visited{
    color: #404040;
}

.footInfo a:hover{
    color: #51c0db;
    text-decoration: underline;
}

.footHead a:active{
    color: #51c0db;
}

.footHead a:link{
    color: #404040;
    text-decoration: none;
}

.footHead a:visited{
    color: #404040;
}

.footHead a:hover{
    color: #51c0db;
}

.footHead a:active{
    color: #51c0db;
}


.faIconFoot {
    font-size: 50px;
    margin: 5px;
}

.footStore {
    width: 150px;
    height: auto;
}


@media screen and (min-width: 550px) {

    .footLogoImg {
        display: block;
        margin-left: 0px;
        margin-right: 0px;
        padding-bottom: 10px;
        width: 200px;
        cursor: pointer;
        z-index: 1;
    }

    .footInfo {
        display: grid;
        grid-template-columns: auto auto;
    
    }

}

@media screen and (min-width: 768px) {

    .footInfo {
        display: grid;
        grid-template-columns: auto auto auto;
    
    }

}

@media screen and (min-width: 960px) {

}

